.faqContainer {
  background-color: #a62d78;
  color: #ffffff;
  padding: 20px;
  border-radius: 20px;
  font-weight: bold;
  min-width: 150px;
  max-width: 250px;
}

.dropdown-container {
  display: flex;
  align-items: center;
  width: fit-content !important;

  span {
    margin-right: 20px;
    color: #005eb8;
  }

  select {
    max-width: 200px !important;
  }
}

.chart_popover {
  border: 2px solid #ff6e40 !important;
  padding: 0 0.75rem 0 0.8rem;
  margin-top: -8px;
}

.closeIcon_chart {
  position: absolute;
  top: 6px;
  cursor: pointer;
  right: 7px;
}

.commonText {
  font-weight: 400;
  font-size: 13px;
}

.popover-footer {
  font-weight: 500;
  font-size: 1rem;
  margin: 0;
  // color: #005eb8;
  white-space: nowrap;
}

// .test_gauge {
//   text[fill="#6E7079"] {
//     text-decoration: underline;
//   }
// }

.insights {
  background-color: #eff6fd;
  height: 80%;
  width: 100%;
  line-height: 25px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  font-weight: 500;
}

.thumb {
  height: 225px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  h5 {
    font-weight: bold;
    margin-top: 25px;
  }
}

.moc-border {
  border: 5px solid orange;
  padding: 10px !important;
}

.diamond {
  width: 7px;
  height: 7px;
  transform: rotate(45deg);
  position: relative;
}
.diamond:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: inherit;
  transform: rotate(90deg);
}

.dashed-line {
  width: 10px;
  margin: 10px 0;
}

.canvasjs-chart-credit {
  display: none !important;
}

.passwordIcons {
  position: absolute;
  margin: 4px auto auto auto;
  right: 45px;
  color: #7bca67;
  z-index: 9;
}

.multiSelectContainer input {
  width: 100%;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 10px !important;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the scrollbar track */
  border-radius: 10px; /* Optional: round the corners */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #a5a5a5; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Optional: round the corners */
  border: 3px solid #f1f1f1; /* Adds a border around the thumb */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #a5a5a5;
}

/* Firefox */
.custom-scrollbar {
  scrollbar-width: thin; /* Width of the scrollbar */
  scrollbar-color: #a5a5a5 #f1f1f1; /* Color of the thumb and track */
}

/* Internet Explorer and Edge */
.custom-scrollbar {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.custom-scrollbar::-ms-scrollbar {
  width: 10px !important;
}

.custom-scrollbar::-ms-scrollbar-track {
  background: #f1f1f1;
}

.custom-scrollbar::-ms-scrollbar-thumb {
  background: #a5a5a5;
  border-radius: 10px;
  border: 3px solid #f1f1f1;
}

.custom-scrollbar::-ms-scrollbar-thumb:hover {
  background: #a5a5a5;
}

.rotated-text {
  display: inline-block; /* Make sure the element respects the transform */
  transform: rotate(-90deg); /* Rotate the text 90 degrees */
  white-space: nowrap; /* Prevent text wrapping */
  position: absolute;
  left: -75px;
  z-index: 1;
  bottom: 50%;
  color: #6e7079;
}

.table1 tbody tr td {
  background-color: white;
}

.table1 thead tr th {
  background-color: #d9e5f1;
  border-bottom-color: #dddddd !important;
}

.islington_g path,
.haringey_g path,
.camden_g path,
.barnet_g path,
.enfield_g path {
  // transition-duration: 1s;
  // transform-origin: center;
  // transform-box: fill-box;
}

.islington_g path:hover,
.haringey_g path:hover,
.camden_g path:hover,
.barnet_g path:hover,
.enfield_g path:hover {
  cursor: pointer;
  // transform: scale(0.9);
}

.islington_g circle:hover,
.haringey_g circle:hover,
.camden_g circle:hover,
.barnet_g circle:hover,
.enfield_g circle:hover {
  cursor: pointer;
  // transform: scale(0.9);
}

.popover_on_map {
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  display: none;
  pointer-events: none;
}

.rankCol {
  background-color: #E9F4FD !important;
}
